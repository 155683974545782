import React from 'react'

import * as styles from './signup-device.module.css'
import ValidIcon from './assets/green-check.svg'
import InvalidIcon from './assets/red-x.svg'

type SignupDeviceProps = {
  device: string
}

export const EligibleDevice = ({ device }: SignupDeviceProps) => {
  return (
    <li className={`${styles.device} ${styles.eligible}`}>
      <ValidIcon />
      <p>Thermostat - {device}</p>
    </li>
  )
}

export const IneligibleDevice = ({ device }: SignupDeviceProps) => {
  return (
    <li className={`${styles.device} ${styles.ineligible}`}>
      <InvalidIcon />
      <p>Thermostat - {device}</p>
    </li>
  )
}
