import React from 'react'
import SignupDeviceList from 'components/signup-device-list'
import { formatHvacSystemTypes } from 'lib/signup-form-helpers'
import { UWPSignup } from 'types/uwp'

const AllDeviceStatuses = ({ signupObj }: { signupObj: UWPSignup }) => {
  const devicesByStatus =
    signupObj?.eligibilityResult?.enrollmentMessage?.devicesByStatus

  return (
    <>
      {devicesByStatus?.ELIGIBLE && (
        <SignupDeviceList
          devices={devicesByStatus.ELIGIBLE.identifiers}
          heading="These thermostats will be enrolled:"
          successful={true}
        />
      )}
      {devicesByStatus?.INELIGIBLE_DR && (
        <SignupDeviceList
          devices={devicesByStatus.INELIGIBLE_DR.identifiers}
          heading="These thermostats are not compatible with Demand Response:"
          successful={false}
        />
      )}
      {devicesByStatus?.INELIGIBLE_MODEL && (
        <SignupDeviceList
          devices={devicesByStatus.INELIGIBLE_MODEL.identifiers}
          heading="The following thermostats are not eligible to participate:"
          successful={false}
        />
      )}
      {devicesByStatus?.INELIGIBLE_OFFLINE && (
        <SignupDeviceList
          devices={devicesByStatus.INELIGIBLE_OFFLINE.identifiers}
          heading="The following thermostats are offline, so they are not eligible to participate:"
          successful={false}
        />
      )}
      {devicesByStatus?.INELIGIBLE_INSTALL_DATE && (
        <SignupDeviceList
          devices={devicesByStatus.INELIGIBLE_INSTALL_DATE.identifiers}
          heading={`The following thermostats were installed before ${
            devicesByStatus.INELIGIBLE_INSTALL_DATE.metadata.minimumInstallDate
              ? new Date(
                  devicesByStatus.INELIGIBLE_INSTALL_DATE.metadata.minimumInstallDate
                ).toLocaleDateString('sv')
              : "the program's minimum install date"
          }, so they are not eligible to participate:`}
          successful={false}
        />
      )}
      {devicesByStatus?.INELIGIBLE_REQUIRED_SERVICES && (
        <SignupDeviceList
          devices={devicesByStatus.INELIGIBLE_REQUIRED_SERVICES.identifiers}
          heading="The following thermostats are not eligible to participate:"
          successful={false}
        />
      )}
      {devicesByStatus?.INELIGIBLE_HVAC_SYSTEM && (
        <SignupDeviceList
          devices={devicesByStatus.INELIGIBLE_HVAC_SYSTEM.identifiers}
          heading={`The following thermostats do not control a(n) ${formatHvacSystemTypes(
            devicesByStatus.INELIGIBLE_HVAC_SYSTEM.metadata.hvacSystemTypes ||
              []
          )} system, so they are not eligible to participate:`}
          successful={false}
        />
      )}
    </>
  )
}

export default AllDeviceStatuses
