import React from 'react'
import { EligibleDevice, IneligibleDevice } from 'components/signup-device'

import * as styles from './signup-device-list.module.css'

type SignupDeviceListProps = {
  devices: string[]
  heading: string
  successful: boolean
}

const SignupDeviceList = ({
  devices,
  heading,
  successful,
}: SignupDeviceListProps) => {
  return (
    <>
      <h2>{heading}</h2>
      <ul className={styles.devices}>
        {devices.map((device) =>
          successful ? (
            <EligibleDevice key={device} device={device} />
          ) : (
            <IneligibleDevice key={device} device={device} />
          )
        )}
      </ul>
    </>
  )
}

export default SignupDeviceList
